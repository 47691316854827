function performSearch(queryInput) {
    toggleLoader(true);

    fetch('/search', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify({
            input: queryInput,
            input: queryInput || 'Croatia',
        }),
    })
        .then((response) => response.json())
        .then((res) => {
            const path = getPathAfterDomain(res.output.url);
            const decodedPath = decodeURIComponent(path);
            window.location.href = window.location.origin + decodedPath;
            sessionStorage.setItem('queryInput', queryInput);
            toggleLoader(false);
        });
}

function toggleLoader(show) {
    const loaders = document.querySelectorAll('.blade-loader');
    const results = document.querySelectorAll('.results');

    loaders.forEach((loader) => {
        if (show) {
            loader.classList.remove('tw-hidden');
            loader.classList.add('tw-block');
        } else {
            loader.classList.add('tw-hidden');
            loader.classList.remove('tw-block');
        }
    });

    results.forEach((result) => {
        if (show) {
            result.classList.add('tw-hidden');
            result.classList.remove('tw-block');
        } else {
            result.classList.remove('tw-hidden');
            result.classList.add('tw-block');
        }
    });
}

function getPathAfterDomain(url) {
    const regex = /\.com(\/.*)?$/;
    const match = url.match(regex);
    return match ? match[0].substring(4) : '';
}

(function () {
    const infoModal = document.getElementById('info-modal');
    const body = document.getElementsByTagName('body')[0];
    if (infoModal) {
        const openInfoModals = document.querySelectorAll('.open-info-modal');

        openInfoModals.forEach((openInfoModal) => {
            openInfoModal.addEventListener('click', function (e) {
                e.preventDefault();
                if (infoModal.classList.contains('tw-flex')) {
                    infoModal.classList.remove('tw-flex');
                    body.classList.remove('prevent-scroll');
                    infoModal.classList.add('tw-hidden');
                } else {
                    infoModal.classList.add('tw-flex');
                    body.classList.add('prevent-scroll');
                    infoModal.classList.remove('tw-hidden');
                }

                const closeBtn = infoModal.querySelector('.close');
                closeBtn.addEventListener('click', function (e) {
                    e.preventDefault();
                    infoModal.classList.remove('tw-flex');
                    body.classList.remove('prevent-scroll');
                    infoModal.classList.add('tw-hidden');
                });
            });
        });
    }
})();

//Toggling search suggestions box and search bar focus state
(function () {
    const suggestionsBox = document.getElementById('suggestions');
    const queryInput = document.getElementById('query-input');
    const queryInputWrapper = document.getElementById('query-input-wrapper');
    const searchBoxWrapper = document.getElementById('search-box-wrapper');

    function handleFocus() {
        if (queryInputWrapper && suggestionsBox && searchBoxWrapper) {
            queryInputWrapper.classList.remove('tw-border-light-grey2');
            queryInputWrapper.classList.add('tw-border-dark-green');
            suggestionsBox.classList.remove('tw-hidden');
            suggestionsBox.classList.add('tw-block');
            searchBoxWrapper.classList.remove('tw-rounded-lg');
            searchBoxWrapper.classList.add('tw-rounded-t-lg');
        }
    }

    function handleBlur() {
        if (queryInputWrapper) {
            queryInputWrapper.classList.remove('tw-border-dark-green');
            queryInputWrapper.classList.add('tw-border-light-grey2');
        }
    }

    if (queryInput) {
        queryInput.addEventListener('focus', handleFocus);
        queryInput.addEventListener('blur', handleBlur);
    }
})();

//TODO Can this method be reused so it doesnt repeat itself for both searchboxes?
(function () {
    const queryInput = document.getElementById('query-input-map');
    const queryInputWrapper = document.getElementById('query-input-wrapper');

    function handleFocus() {
        if (queryInputWrapper) {
            queryInputWrapper.classList.remove('tw-border-light-grey2');
            queryInputWrapper.classList.add('tw-border-dark-green');
        }
    }

    function handleBlur() {
        if (queryInputWrapper) {
            queryInputWrapper.classList.remove('tw-border-dark-green');
            queryInputWrapper.classList.add('tw-border-light-grey2');
        }
    }

    if (queryInput) {
        queryInput.addEventListener('focus', handleFocus);
        queryInput.addEventListener('blur', handleBlur);
    }
})();

//Populate query-input-map input with the query from the homescreen
(function () {
    const queryInputMap = document.getElementById('query-input-map');

    if (queryInputMap) {
        const storedQuery = sessionStorage.getItem('queryInput');

        // If there's a stored value, populate the query-input-map field
        if (storedQuery) queryInputMap.value = storedQuery;
    }
})();

// Button for clearing the search field
(function () {
    function setupReset(inputId, buttonId) {
        const queryInput = document.getElementById(inputId);
        const resetButton = document.getElementById(buttonId);

        // Only proceed if elements are present
        if (!queryInput || !resetButton) return;

        let isVisible = false;

        function toggleResetButton() {
            const hasValue = queryInput.value.trim() !== '';

            if (hasValue !== isVisible) {
                resetButton.classList.toggle('tw-invisible', !hasValue);
                resetButton.classList.toggle('tw-opacity-0', !hasValue);
                resetButton.classList.toggle('tw-visible', hasValue);
                resetButton.classList.toggle('tw-opacity-1', hasValue);
                // Cache visibility state
                isVisible = hasValue;
            }
        }

        function resetInput() {
            queryInput.value = '';
            sessionStorage.removeItem('queryInput');
        }

        function handleReset() {
            resetInput();
            toggleResetButton();
        }

        resetButton.addEventListener('click', handleReset);
        queryInput.addEventListener('input', toggleResetButton);
        document.addEventListener('DOMContentLoaded', toggleResetButton);
    }
    // Set up reset functionality for different input/button pairs
    setupReset('query-input', 'reset-button');
    setupReset('query-input-map', 'reset-button-map');
    setupReset('query-input-fullscreen', 'reset-button-fullscreen');
})();

//Search Suggestions
(function () {
    document.addEventListener('DOMContentLoaded', function () {
        const suggestionButtons = document.querySelectorAll('.suggestion-btn');
        suggestionButtons.forEach((btn) => {
            const suggestionText = btn.textContent.trim();

            btn.addEventListener('click', function () {
                performSearch(suggestionText);
            });
        });
    });
})();

//Placeholder Typewriter Effect
(function () {
    const queryInput = document.getElementById('query-input');
    if (!queryInput) return;
    const placeholderText = queryInput.getAttribute('placeholder');
    let index = 0;
    let typingTimeout;

    function typeWriterEffect() {
        if (index < placeholderText.length) {
            queryInput.setAttribute('placeholder', placeholderText.substring(0, index + 1));
            index++;
            typingTimeout = setTimeout(typeWriterEffect, 50);
        }
    }

    queryInput.addEventListener('focus', () => {
        clearTimeout(typingTimeout);
        queryInput.setAttribute('placeholder', '');
    });

    queryInput.addEventListener('blur', () => {
        queryInput.setAttribute('placeholder', placeholderText);
    });

    window.onload = typeWriterEffect;
})();

//Search
(function () {
    function addSearchEventListener(buttonId, inputId) {
        const searchButton = document.getElementById(buttonId);
        const queryInput = document.getElementById(inputId);

        if (searchButton) {
            searchButton.addEventListener('click', function () {
                const query = queryInput.value.trim();
                performSearch(query);
            });
        }

        if (queryInput) {
            queryInput.addEventListener('keypress', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    const query = queryInput.value.trim();
                    performSearch(query);
                }
            });
        }
    }

    // Attach the event listeners
    addSearchEventListener('search-button', 'query-input');
    addSearchEventListener('search-button-map', 'query-input-map');
    addSearchEventListener('search-button-fullscreen', 'query-input-fullscreen');
    addSearchEventListener('search-button-fullscreen-off', 'search-input-trigger');
})();

//Toggle mobile searchbox
(function () {
    const elements = {
        fullscreenSearchInputWrapper: document.getElementById('query-input-wrapper-fullscreen'),
        searchInputTrigger: document.getElementById('search-input-trigger'),
        mobileSearchContainer: document.getElementById('mobile-search-container'),
        fullscreenSearchInput: document.getElementById('query-input-fullscreen'),
        closeFullscreenButton: document.getElementById('close-fullscreen-btn'),
    };

    function updateWrapperBorder(addDarkGreen) {
        elements.fullscreenSearchInputWrapper.classList.toggle('tw-border-dark-green', addDarkGreen);
        elements.fullscreenSearchInputWrapper.classList.toggle('tw-border-light-grey2', !addDarkGreen);
    }

    function toggleFullscreen(isFullscreen) {
        elements.mobileSearchContainer.classList.toggle('fullscreen', isFullscreen);
        isFullscreen ? elements.fullscreenSearchInput.focus() : elements.fullscreenSearchInput.blur();
        updateWrapperBorder(isFullscreen);
    }

    if (elements.fullscreenSearchInput) {
        elements.fullscreenSearchInput.addEventListener('focus', () => updateWrapperBorder(true));
        elements.fullscreenSearchInput.addEventListener('blur', () => updateWrapperBorder(false));
    }

    if (elements.searchInputTrigger)
        elements.searchInputTrigger.addEventListener('focus', () => toggleFullscreen(true));

    if (elements.closeFullscreenButton)
        elements.closeFullscreenButton.addEventListener('click', () => toggleFullscreen(false));
})();
